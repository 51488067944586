// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "ca\/user-service\/register-intent-success",
        "route": "ca\/user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "ca\/keycloak\/contains",
        "route": "ca\/keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "ca\/social-network-access\/<socialNetwork>",
        "route": "ca\/social-network-access\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "ca\/error",
        "route": "ca\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET ca\/ser\/denuncia\/<idSERFine:\\w+>\/detall",
        "route": "ca\/ser\/denuncia\/:idSERFine\/detall",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST ca\/usuari",
        "route": "ca\/usuari",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT ca\/usuari\/<id:\\d+>",
        "route": "ca\/usuari\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT ca\/user-basic\/<id:\\d+>",
        "route": "ca\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "ca\/desconnectar",
        "route": "ca\/desconnectar",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT ca\/baixa-usuari",
        "route": "ca\/baixa-usuari",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "ca\/baixa-usuari\/<token>",
        "route": "ca\/baixa-usuari\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "ca\/el-meu-compte",
        "route": "ca\/el-meu-compte",
        "params": []
    },
    "account\/user": {
        "original_route": "ca\/les-meves-dades",
        "route": "ca\/les-meves-dades",
        "params": []
    },
    "account\/security": {
        "original_route": "ca\/seguretat",
        "route": "ca\/seguretat",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "ca\/compte\/social-login\/<social_network_name>",
        "route": "ca\/compte\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "ca\/els-meus-vehicles",
        "route": "ca\/els-meus-vehicles",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "ca\/els-meus-vehicles\/<page:\\d+>",
        "route": "ca\/els-meus-vehicles\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "ca\/els-remolc",
        "route": "ca\/els-remolc",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "ca\/els-remolc\/<page:\\d+>",
        "route": "ca\/els-remolc\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST ca\/recuperar-contrasenya",
        "route": "ca\/recuperar-contrasenya",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "ca\/recuperar-contrasenya",
        "route": "ca\/recuperar-contrasenya",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST ca\/canviar-contrasenya\/<token>",
        "route": "ca\/canviar-contrasenya\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "ca\/canviar-contrasenya\/<token>",
        "route": "ca\/canviar-contrasenya\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "ca\/sollicitar-canvi-email",
        "route": "ca\/sollicitar-canvi-email",
        "params": []
    },
    "account\/update-email": {
        "original_route": "ca\/canviar-email\/<token>\/<newEmail>",
        "route": "ca\/canviar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "ca\/confirmacio-canviar-email\/<code>",
        "route": "ca\/confirmacio-canviar-email\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "ca\/connectar",
        "route": "ca\/connectar",
        "params": []
    },
    "account\/end-process": {
        "original_route": "ca\/end-process",
        "route": "ca\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "ca\/registre",
        "route": "ca\/registre",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "ca\/canviar-contrasenya",
        "route": "ca\/canviar-contrasenya",
        "params": []
    },
    "site\/terms": {
        "original_route": "ca\/termes-legals",
        "route": "ca\/termes-legals",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "ca\/politica-privacitat-cookies",
        "route": "ca\/politica-privacitat-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "ca\/transpar\u00e8ncia",
        "route": "ca\/transpar\u00e8ncia",
        "params": []
    },
    "site\/security-policy": {
        "original_route": "ca\/politica-seguretat",
        "route": "ca\/politica-seguretat",
        "params": []
    },
    "account\/validate": {
        "original_route": "ca\/confirmacio\/<token>",
        "route": "ca\/confirmacio\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "ca\/comande\/viat\/<id:\\d+>",
        "route": "ca\/comande\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "ca\/comandes",
        "route": "ca\/comandes",
        "params": []
    },
    "order\/page": {
        "original_route": "ca\/comandes\/<page:\\d+>",
        "route": "ca\/comandes\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "ca\/comandes\/viat",
        "route": "ca\/comandes\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "ca\/comandes\/viat\/<page:\\d+>",
        "route": "ca\/comandes\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "ca\/commanda\/transits",
        "route": "ca\/commanda\/transits",
        "params": []
    },
    "toll\/page": {
        "original_route": "ca\/commanda\/transits\/<page:\\d+>",
        "route": "ca\/commanda\/transits\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT ca\/mobe\/<id:\\w*>\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT ca\/mobe\/<id:\\w*>\/desassignar-vehicle",
        "route": "ca\/mobe\/:id\/desassignar-vehicle",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT ca\/mobe\/<id:\\w*>\/desassignar",
        "route": "ca\/mobe\/:id\/desassignar",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET ca\/mobe\/buscar",
        "route": "ca\/mobe\/buscar",
        "params": []
    },
    "toll\/search": {
        "original_route": "GET ca\/transits\/buscar",
        "route": "ca\/transits\/buscar",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT ca\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "ca\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET ca\/pagament-mobil-estacionament-regulat",
        "route": "ca\/pagament-mobil-estacionament-regulat",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET ca\/tiquet\/<idSERTicket:\\w+>\/detall",
        "route": "ca\/tiquet\/:idSERTicket\/detall",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET ca\/tiquet\/<idSERTicket:\\w+>\/enviar-email",
        "route": "ca\/tiquet\/:idSERTicket\/enviar-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET ca\/tiquet\/<idSERTicket:\\w+>.pdf",
        "route": "ca\/tiquet\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "ca\/aparcar-a-<slug>\/treure-tiquet",
        "route": "ca\/aparcar-a-:slug\/treure-tiquet",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST ca\/tiquets-ser\/treure-tiquet",
        "route": "ca\/tiquets-ser\/treure-tiquet",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST ca\/tiquet\/<id>\/finalitzar-tiquet",
        "route": "ca\/tiquet\/:id\/finalitzar-tiquet",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET ca\/tiquet\/<id>\/finalitzar-tiquet",
        "route": "ca\/tiquet\/:id\/finalitzar-tiquet",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "ca\/tiquet\/<id>\/nou",
        "route": "ca\/tiquet\/:id\/nou",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "ca\/moneder\/forma-de-pagament",
        "route": "ca\/moneder\/forma-de-pagament",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "ca\/moneder",
        "route": "ca\/moneder",
        "params": []
    },
    "wallet\/show": {
        "original_route": "ca\/moneder\/<idWallet:\\d+>\/detall",
        "route": "ca\/moneder\/:idWallet\/detall",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "ca\/moneder\/<idWallet:\\d+>\/data",
        "route": "ca\/moneder\/:idWallet\/data",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "ca\/moneder\/<idWallet:\\d+>\/beneficiaris",
        "route": "ca\/moneder\/:idWallet\/beneficiaris",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "ca\/moneders",
        "route": "ca\/moneders",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT ca\/moneder\/deute",
        "route": "ca\/moneder\/deute",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST ca\/moneder\/recarrega",
        "route": "ca\/moneder\/recarrega",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST ca\/moneder\/confirm-payment-intent",
        "route": "ca\/moneder\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST ca\/moneder\/<idWallet:\\d+>\/associar-targeta\/<idCreditCard:\\d+>",
        "route": "ca\/moneder\/:idWallet\/associar-targeta\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET ca\/facturacio\/factures\/<invoiceUid>.pdf",
        "route": "ca\/facturacio\/factures\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET ca\/facturacio\/factures",
        "route": "ca\/facturacio\/factures",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET ca\/facturacio\/factures\/<page:\\d+>",
        "route": "ca\/facturacio\/factures\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT ca\/facturacio\/dades",
        "route": "ca\/facturacio\/dades",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET ca\/buscar-parquing",
        "route": "ca\/buscar-parquing",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET ca\/buscar-parquing-aeroport",
        "route": "ca\/buscar-parquing-aeroport",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET ca\/buscar-abonament",
        "route": "ca\/buscar-abonament",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST ca\/buscar-abonament",
        "route": "ca\/buscar-abonament",
        "params": []
    },
    "booking\/process": {
        "original_route": "GET ca\/confirmar-reserva\/<idPrebooking:\\d+>",
        "route": "ca\/confirmar-reserva\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET ca\/confirmar-reserva-itv",
        "route": "ca\/confirmar-reserva-itv",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET ca\/guantera\/actuales",
        "route": "ca\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET ca\/guantera\/pasados\/<page:\\d+>",
        "route": "ca\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/show": {
        "original_route": "GET ca\/reserva\/<idBooking:\\d+>\/veure",
        "route": "ca\/reserva\/:idBooking\/veure",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET ca\/reserva\/<idBooking:\\d+>\/nova",
        "route": "ca\/reserva\/:idBooking\/nova",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT ca\/reserva\/cancellar\/<idBooking:\\d+>",
        "route": "ca\/reserva\/cancellar\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET ca\/vehicle-marca\/<brandId:\\d+>\/models",
        "route": "ca\/vehicle-marca\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET ca\/vehicle",
        "route": "ca\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET ca\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE ca\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST ca\/vehicle",
        "route": "ca\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT ca\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET ca\/abonament\/<seasonTicketId:\\d+>\/contractar",
        "route": "ca\/abonament\/:seasonTicketId\/contractar",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET ca\/contractes\/abonament\/<id:\\d+>\/detall",
        "route": "ca\/contractes\/abonament\/:id\/detall",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET ca\/contractes\/abonament\/<id:\\d+>\/veure",
        "route": "ca\/contractes\/abonament\/:id\/veure",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET ca\/contractes\/abonament\/<id:\\d+>\/nou",
        "route": "ca\/contractes\/abonament\/:id\/nou",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST ca\/abonament",
        "route": "ca\/abonament",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT ca\/abonament\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/abonament\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "site\/operators": {
        "original_route": "ca\/operadors",
        "route": "ca\/operadors",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET ca\/preferencies",
        "route": "ca\/preferencies",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT ca\/preferencies\/<id:\\d+>",
        "route": "ca\/preferencies\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET ca\/privacitat",
        "route": "ca\/privacitat",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT ca\/privacitat",
        "route": "ca\/privacitat",
        "params": []
    },
    "wallet-preferences\/index": {
        "original_route": "GET ca\/preferencies-moneder",
        "route": "ca\/preferencies-moneder",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT ca\/preferencies-moneder\/<id:\\d+>",
        "route": "ca\/preferencies-moneder\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET ca\/anullar-denuncia",
        "route": "ca\/anullar-denuncia",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST ca\/denuncia\/check",
        "route": "ca\/denuncia\/check",
        "params": []
    },
    "airport\/show": {
        "original_route": "GET ca\/aparcar-a-aeroport-<slug>\/<id:\\d+>",
        "route": "ca\/aparcar-a-aeroport-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET ca\/aparcar-a-<slug>",
        "route": "ca\/aparcar-a-:slug",
        "params": [
            "slug"
        ]
    },
    "ser-town\/redirect": {
        "original_route": "GET ca\/aparca-a-<slug>",
        "route": "ca\/aparca-a-:slug",
        "params": [
            "slug"
        ]
    },
    "shop\/shop\/invoice": {
        "original_route": "ca\/factura\/<uid>",
        "route": "ca\/factura\/:uid",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "ca\/baixa-correu-comercial\/<email>",
        "route": "ca\/baixa-correu-comercial\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "ca\/botiga\/productes",
        "route": "ca\/botiga\/productes",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "ca\/botiga\/comanda\/<id>",
        "route": "ca\/botiga\/comanda\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET ca\/botiga\/confirmar-comanda\/<id>",
        "route": "ca\/botiga\/confirmar-comanda\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET ca\/botiga\/demanat\/<id>\/nou",
        "route": "ca\/botiga\/demanat\/:id\/nou",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "ca\/en\/auto-recarrega",
        "route": "ca\/en\/auto-recarrega",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "ca\/reserva-taller\/<idBooking:\\d+>\/nova",
        "route": "ca\/reserva-taller\/:idBooking\/nova",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "ca\/reservar-taller\/<idProduct:\\d+>",
        "route": "ca\/reservar-taller\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "ca\/confirmar-reserva-taller\/<idBooking:\\d+>",
        "route": "ca\/confirmar-reserva-taller\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "ca\/reserva-taller\/<idWorkshopBooking:\\d+>\/veure",
        "route": "ca\/reserva-taller\/:idWorkshopBooking\/veure",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "ca\/tallers",
        "route": "ca\/tallers",
        "params": []
    },
    "site\/viat": {
        "original_route": "ca\/viat",
        "route": "ca\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "ca\/viat\/parkings",
        "route": "ca\/viat\/parkings",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "ca\/viat\/portugal",
        "route": "ca\/viat\/portugal",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "ca\/viat\/descomptes",
        "route": "ca\/viat\/descomptes",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "ca\/viat\/funcionament",
        "route": "ca\/viat\/funcionament",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "ca\/com-aparcar",
        "route": "ca\/com-aparcar",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "ca\/parking-aeroport",
        "route": "ca\/parking-aeroport",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "ca\/bescanviar-cupons",
        "route": "ca\/bescanviar-cupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "ca\/comerciant",
        "route": "ca\/comerciant",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "ca\/llogar-garatge",
        "route": "ca\/llogar-garatge",
        "params": []
    },
    "site\/landing-electric-charge": {
        "original_route": "ca\/carrega-electrica",
        "route": "ca\/carrega-electrica",
        "params": []
    },
    "vehicle-inspection-station\/show": {
        "original_route": "ca\/itv\/<slug>\/<id:\\d+>",
        "route": "ca\/itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "ca\/itv",
        "route": "ca\/itv",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "ca\/buscar-itv",
        "route": "ca\/buscar-itv",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET ca\/reserva-itv\/<idBooking:\\d+>\/veure",
        "route": "ca\/reserva-itv\/:idBooking\/veure",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET ca\/rotacio\/<idRotation:\\d+>\/veure",
        "route": "ca\/rotacio\/:idRotation\/veure",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET ca\/reserva-itv\/<idBooking:\\d+>\/nou",
        "route": "ca\/reserva-itv\/:idBooking\/nou",
        "params": [
            "idBooking"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET ca\/zona\/<zoneId>\/vehicle\/<vehicleId:\\d+>",
        "route": "ca\/zona\/:zoneId\/vehicle\/:vehicleId",
        "params": [
            "zoneId",
            "vehicleId"
        ]
    },
    "parking\/show": {
        "original_route": "GET ca\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "ca\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-enterprise": {
        "original_route": "ca\/empreses",
        "route": "ca\/empreses",
        "params": []
    },
    "merchant\/index": {
        "original_route": "ca\/comerciant\/el-meu-compte",
        "route": "ca\/comerciant\/el-meu-compte",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "ca\/comerciant\/botiga",
        "route": "ca\/comerciant\/botiga",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "ca\/comerciant\/botiga\/paso<id:\\d+>",
        "route": "ca\/comerciant\/botiga\/paso:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "ca\/comerciant\/demanat\/<id:\\d+>.pdf",
        "route": "ca\/comerciant\/demanat\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "ca\/comerciantes\/demanat\/<id:\\d+>\/detall",
        "route": "ca\/comerciantes\/demanat\/:id\/detall",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "ca\/merchant\/sample-coupon",
        "route": "ca\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET ca\/moneder\/lista",
        "route": "ca\/moneder\/lista",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT ca\/pagament-reserva\/<idBooking:\\d+>\/moneder\/<idWallet:\\d+>",
        "route": "ca\/pagament-reserva\/:idBooking\/moneder\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST ca\/pagament-abonament\/moneder\/<idWallet:\\d+>",
        "route": "ca\/pagament-abonament\/moneder\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST ca\/treure-tiquet\/moneder\/<idWallet:\\d+>",
        "route": "ca\/treure-tiquet\/moneder\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST ca\/pagar-denuncia\/moneder\/<idWallet:\\d+>",
        "route": "ca\/pagar-denuncia\/moneder\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "kiosk\/reset-password": {
        "original_route": "ca\/quiosc\/recuperar-contrasenya\/<token>",
        "route": "ca\/quiosc\/recuperar-contrasenya\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "ca\/on-boarding-ok",
        "route": "ca\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "ca\/gasolineres",
        "route": "ca\/gasolineres",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "ca\/parkings",
        "route": "ca\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "ca\/aparcar-a-<townSlug>\/parking-<poiSlug>",
        "route": "ca\/aparcar-a-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "ca\/esdeveniments-a-<poiId>\/<month:\\d+>",
        "route": "ca\/esdeveniments-a-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "ca\/itv\/itv-reserva-<poiSlug>",
        "route": "ca\/itv\/itv-reserva-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET ca\/parking\/<idParking:\\d+>\/bo-aparcament\/<id:\\d+>",
        "route": "ca\/parking\/:idParking\/bo-aparcament\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET ca\/bo-aparcament\/<id:\\d+>\/nou",
        "route": "ca\/bo-aparcament\/:id\/nou",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET ca\/bo-aparcament\/<id:\\d+>\/veure",
        "route": "ca\/bo-aparcament\/:id\/veure",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST ca\/masmovil\/rebutjar\/<token>",
        "route": "ca\/masmovil\/rebutjar\/:token",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "ca\/masmovil\/rebutjar\/<token>",
        "route": "ca\/masmovil\/rebutjar\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "ca\/buscar-estacio-carrega-electrica",
        "route": "ca\/buscar-estacio-carrega-electrica",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET ca\/reserva-estacio-carrega-electrica\/<idBooking:\\d+>\/veure",
        "route": "ca\/reserva-estacio-carrega-electrica\/:idBooking\/veure",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "ca\/conductor-plus\/multes-no-notificades",
        "route": "ca\/conductor-plus\/multes-no-notificades",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "ca\/conductor-plus\/contractar",
        "route": "ca\/conductor-plus\/contractar",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "ca\/conductor-plus\/subscripcions",
        "route": "ca\/conductor-plus\/subscripcions",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET ca\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "ca\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "ca\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "ca\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "ca\/llogar-cotxes",
        "route": "ca\/llogar-cotxes",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "ca\/finalitzar-llogar-cotxe",
        "route": "ca\/finalitzar-llogar-cotxe",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "ca\/finalitzar-llogar-cotxe\/<id:\\d+>\/detall",
        "route": "ca\/finalitzar-llogar-cotxe\/:id\/detall",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "ca\/finalitzar-llogar-cotxe\/cerca",
        "route": "ca\/finalitzar-llogar-cotxe\/cerca",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "ca\/finalitzar-llogar-cotxe\/veure\/<unbornId:\\d+>\/<uuid>",
        "route": "ca\/finalitzar-llogar-cotxe\/veure\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "ca\/finalitzar-llogar-cotxe\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "ca\/finalitzar-llogar-cotxe\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "esp\/sitemap\/lang": {
        "original_route": "ca\/sitemap.xml",
        "route": "ca\/sitemap.xml",
        "params": []
    },
    "site\/index": {
        "original_route": "ca",
        "route": "ca",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/ca",
        "route": "esp\/ca",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "ca\/completar-perfil",
        "route": "ca\/completar-perfil",
        "params": []
    }
};