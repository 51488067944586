// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "en\/user-service\/register-intent-success",
        "route": "en\/user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "en\/keycloak\/contains",
        "route": "en\/keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "en\/social-network-access\/<socialNetwork>",
        "route": "en\/social-network-access\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "en\/error",
        "route": "en\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET en\/ser\/complaint\/<idSERFine:\\w>\/detail",
        "route": "en\/ser\/complaint\/:idSERFine\/detail",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST en\/user",
        "route": "en\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT en\/user\/<id:\\d+>",
        "route": "en\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT en\/user-basic\/<id:\\d+>",
        "route": "en\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "en\/disconnect",
        "route": "en\/disconnect",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT en\/remove-user",
        "route": "en\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "en\/remove-user\/<token>",
        "route": "en\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "en\/my-account",
        "route": "en\/my-account",
        "params": []
    },
    "account\/user": {
        "original_route": "en\/my-details",
        "route": "en\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "en\/security",
        "route": "en\/security",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "en\/account\/social-login\/<social_network_name>",
        "route": "en\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "en\/my-cars",
        "route": "en\/my-cars",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "en\/my-cars\/<page:\\d+>",
        "route": "en\/my-cars\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "en\/my-trailers",
        "route": "en\/my-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "en\/my-trailers\/<page:\\d+>",
        "route": "en\/my-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST en\/recover-password",
        "route": "en\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "en\/recover-password",
        "route": "en\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST en\/change-password\/<token>",
        "route": "en\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "en\/change-password\/<token>",
        "route": "en\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "en\/email-change-request",
        "route": "en\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "en\/change-email\/<token>\/<newEmail>",
        "route": "en\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "en\/change-email-confirm\/<code>",
        "route": "en\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "en\/connect",
        "route": "en\/connect",
        "params": []
    },
    "account\/end-process": {
        "original_route": "en\/end-process",
        "route": "en\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "en\/register",
        "route": "en\/register",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "en\/change-password",
        "route": "en\/change-password",
        "params": []
    },
    "site\/terms": {
        "original_route": "en\/legal-terms",
        "route": "en\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "en\/privacy-policy-cookies",
        "route": "en\/privacy-policy-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "en\/transparency",
        "route": "en\/transparency",
        "params": []
    },
    "site\/security-policy": {
        "original_route": "en\/security-policy",
        "route": "en\/security-policy",
        "params": []
    },
    "account\/validate": {
        "original_route": "en\/validate\/<token>",
        "route": "en\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "en\/order\/viat\/<id:\\d+>",
        "route": "en\/order\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "en\/orders",
        "route": "en\/orders",
        "params": []
    },
    "order\/page": {
        "original_route": "en\/orders\/<page:\\d+>",
        "route": "en\/orders\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "en\/orders\/viat",
        "route": "en\/orders\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "en\/orders\/viat\/<page:\\d+>",
        "route": "en\/orders\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "en\/orders\/tolls",
        "route": "en\/orders\/tolls",
        "params": []
    },
    "toll\/page": {
        "original_route": "en\/orders\/tolls\/<page:\\d+>",
        "route": "en\/orders\/tolls\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT en\/mobe\/<id:\\w*>\/vehicle\/<vehicleId:\\d+>",
        "route": "en\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT en\/mobe\/<id:\\w*>\/unbind-vehicle",
        "route": "en\/mobe\/:id\/unbind-vehicle",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT en\/mobe\/<id:\\w*>\/unassign",
        "route": "en\/mobe\/:id\/unassign",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET en\/mobe\/search",
        "route": "en\/mobe\/search",
        "params": []
    },
    "toll\/search": {
        "original_route": "GET en\/toll\/search",
        "route": "en\/toll\/search",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT en\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "en\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET en\/regulated-mobile-parking-payment",
        "route": "en\/regulated-mobile-parking-payment",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET en\/ticket\/<idSERTicket:\\w+>\/detail",
        "route": "en\/ticket\/:idSERTicket\/detail",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET en\/ticket\/<idSERTicket:\\w+>\/send-email",
        "route": "en\/ticket\/:idSERTicket\/send-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET en\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "en\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "en\/park-in-<slug>\/take-ticket",
        "route": "en\/park-in-:slug\/take-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST en\/ticket-be\/get-ticket",
        "route": "en\/ticket-be\/get-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST en\/ticket\/<id>\/unpark",
        "route": "en\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET en\/ticket\/<id>\/unpark",
        "route": "en\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "en\/new\/<id>\/ticket",
        "route": "en\/new\/:id\/ticket",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "en\/wallet\/payment-method",
        "route": "en\/wallet\/payment-method",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "en\/wallet",
        "route": "en\/wallet",
        "params": []
    },
    "wallet\/show": {
        "original_route": "en\/wallet\/<idWallet:\\d+>\/show",
        "route": "en\/wallet\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "en\/wallet\/<idWallet:\\d+>\/beneficiaries",
        "route": "en\/wallet\/:idWallet\/beneficiaries",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "en\/wallet\/<idWallet:\\d+>\/data",
        "route": "en\/wallet\/:idWallet\/data",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "en\/wallets",
        "route": "en\/wallets",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT en\/wallet\/pay-debt",
        "route": "en\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST en\/wallet\/top-up",
        "route": "en\/wallet\/top-up",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST en\/wallet\/confirm-payment-intent",
        "route": "en\/wallet\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST en\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "en\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET en\/billing\/invoices\/<invoiceUid>.pdf",
        "route": "en\/billing\/invoices\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET en\/download-billing\/<invoiceUid>.xlsx",
        "route": "en\/download-billing\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET en\/billing\/invoices",
        "route": "en\/billing\/invoices",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET en\/billing\/invoices\/<page:\\d+>",
        "route": "en\/billing\/invoices\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT en\/billing\/data",
        "route": "en\/billing\/data",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET en\/search-for-parking-space",
        "route": "en\/search-for-parking-space",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET en\/search-airport-parking",
        "route": "en\/search-airport-parking",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET en\/search-subscription",
        "route": "en\/search-subscription",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST en\/search-subscription",
        "route": "en\/search-subscription",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET en\/glovie\/list-actives",
        "route": "en\/glovie\/list-actives",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET en\/glovie\/list-inactives\/<page:\\d+>",
        "route": "en\/glovie\/list-inactives\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/process": {
        "original_route": "GET en\/confirm-booking\/<idPrebooking:\\d+>",
        "route": "en\/confirm-booking\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET en\/confirm-booking-vehicle-inspection",
        "route": "en\/confirm-booking-vehicle-inspection",
        "params": []
    },
    "booking\/show": {
        "original_route": "GET en\/reservation\/<idBooking:\\d+>\/view",
        "route": "en\/reservation\/:idBooking\/view",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET en\/reservation\/<idBooking:\\d+>\/new",
        "route": "en\/reservation\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT en\/reservation\/cancel\/<idBooking:\\d+>",
        "route": "en\/reservation\/cancel\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET en\/vehicle-brand\/<brandId:\\d+>\/models",
        "route": "en\/vehicle-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET en\/vehicle",
        "route": "en\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET en\/vehicle\/<vehicleid:\\d+>",
        "route": "en\/vehicle\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE en\/vehicle\/<vehicleId:\\d+>",
        "route": "en\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST en\/vehicle",
        "route": "en\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT en\/vehicle\/<vehicleId:\\d+>",
        "route": "en\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET en\/subscription\/<seasonTicketId:\\d+>\/get",
        "route": "en\/subscription\/:seasonTicketId\/get",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET en\/subscription\/payment\/<id:\\d+>\/details",
        "route": "en\/subscription\/payment\/:id\/details",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET en\/subscription\/payment\/<id:\\d+>\/view",
        "route": "en\/subscription\/payment\/:id\/view",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET en\/subscription\/payment\/<id:\\d+>\/new",
        "route": "en\/subscription\/payment\/:id\/new",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST en\/subscription",
        "route": "en\/subscription",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT en\/subscription\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "en\/subscription\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE en\/subscription\/<idContract:\\d+>",
        "route": "en\/subscription\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "site\/operators": {
        "original_route": "en\/operators",
        "route": "en\/operators",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET en\/preferences",
        "route": "en\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT en\/preferences\/<id:\\d+>",
        "route": "en\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET en\/privacy",
        "route": "en\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT en\/privacy",
        "route": "en\/privacy",
        "params": []
    },
    "wallet-preferences\/index": {
        "original_route": "GET en\/wallet-preferences",
        "route": "en\/wallet-preferences",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT en\/wallet-preferences\/<id:\\d+>",
        "route": "en\/wallet-preferences\/:id",
        "params": [
            "id"
        ]
    },
    "airport\/show": {
        "original_route": "GET en\/park-in-airport-<slug>\/<id:\\d+>",
        "route": "en\/park-in-airport-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET en\/park-in-<slug>",
        "route": "en\/park-in-:slug",
        "params": [
            "slug"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET en\/zone\/<zoneid>\/vehicle\/<vehicleid:\\d+>",
        "route": "en\/zone\/:zoneid\/vehicle\/:vehicleid",
        "params": [
            "zoneid",
            "vehicleid"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET en\/glovie\/fines",
        "route": "en\/glovie\/fines",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST en\/ser-fine\/check",
        "route": "en\/ser-fine\/check",
        "params": []
    },
    "shop\/shop\/invoice": {
        "original_route": "en\/invoice\/<uid>",
        "route": "en\/invoice\/:uid",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "en\/unsubscribe\/<email>",
        "route": "en\/unsubscribe\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "en\/shop\/products",
        "route": "en\/shop\/products",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "en\/shop\/order\/<id>",
        "route": "en\/shop\/order\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET en\/shop\/confirm-command\/<id>",
        "route": "en\/shop\/confirm-command\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET en\/shop\/order\/<id>\/new",
        "route": "en\/shop\/order\/:id\/new",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "en\/auto-deposit",
        "route": "en\/auto-deposit",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "en\/workshop-booking\/<idBooking:\\d+>\/new",
        "route": "en\/workshop-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "en\/book-workshop\/<idProduct:\\d+>",
        "route": "en\/book-workshop\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "en\/confirm-workshop-booking\/<idBooking:\\d+>",
        "route": "en\/confirm-workshop-booking\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "en\/workshop-booking\/<idWorkshopBooking:\\d+>\/show",
        "route": "en\/workshop-booking\/:idWorkshopBooking\/show",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "en\/workshops",
        "route": "en\/workshops",
        "params": []
    },
    "site\/viat": {
        "original_route": "en\/viat",
        "route": "en\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "en\/viat\/parkings",
        "route": "en\/viat\/parkings",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "en\/viat\/portugal",
        "route": "en\/viat\/portugal",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "en\/viat\/discounts",
        "route": "en\/viat\/discounts",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "en\/viat\/how-it-works",
        "route": "en\/viat\/how-it-works",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "en\/how-to-park",
        "route": "en\/how-to-park",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "en\/airport-parking",
        "route": "en\/airport-parking",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "en\/parking-sharing",
        "route": "en\/parking-sharing",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "en\/redeem-coupons",
        "route": "en\/redeem-coupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "en\/merchant",
        "route": "en\/merchant",
        "params": []
    },
    "site\/landing-enterprise": {
        "original_route": "en\/enterprise",
        "route": "en\/enterprise",
        "params": []
    },
    "merchant\/index": {
        "original_route": "en\/merchant\/account",
        "route": "en\/merchant\/account",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "en\/merchant\/shop",
        "route": "en\/merchant\/shop",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "en\/merchant\/shop\/step<id:\\d+>",
        "route": "en\/merchant\/shop\/step:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "en\/merchant\/order\/<id:\\d+>.pdf",
        "route": "en\/merchant\/order\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "en\/merchant\/order\/<id:\\d+>\/detail",
        "route": "en\/merchant\/order\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "en\/merchant\/sample-coupon",
        "route": "en\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET en\/wallet\/list",
        "route": "en\/wallet\/list",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT en\/pay-booking\/<idBooking:\\d+>\/wallet\/<idWallet:\\d+>",
        "route": "en\/pay-booking\/:idBooking\/wallet\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST en\/pay-season-ticket-contract\/wallet\/<idWallet:\\d+>",
        "route": "en\/pay-season-ticket-contract\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST en\/pay-ticket\/wallet\/<idWallet:\\d+>",
        "route": "en\/pay-ticket\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST en\/pay-ser-fine\/wallet\/<idWallet:\\d+>",
        "route": "en\/pay-ser-fine\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "vehicle-inspection-station\/show": {
        "original_route": "en\/itv\/<slug>\/<id:\\d+>",
        "route": "en\/itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "en\/itv",
        "route": "en\/itv",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "en\/search-vehicle-inspection-station",
        "route": "en\/search-vehicle-inspection-station",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET en\/vehicle-inspection-booking\/<idBooking:\\d+>\/show",
        "route": "en\/vehicle-inspection-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET en\/rotation\/<idRotation:\\d+>\/show",
        "route": "en\/rotation\/:idRotation\/show",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET en\/vehicle-inspection-booking\/<idBooking:\\d+>\/new",
        "route": "en\/vehicle-inspection-booking\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "parking\/show": {
        "original_route": "GET en\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "en\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-electric-charge": {
        "original_route": "en\/electric-charge",
        "route": "en\/electric-charge",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "en\/en\/kiosk\/reset-password\/<token>",
        "route": "en\/en\/kiosk\/reset-password\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "en\/on-boarding-ok",
        "route": "en\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "en\/petrol-stations",
        "route": "en\/petrol-stations",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "en\/parkings",
        "route": "en\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "en\/park-in-<townSlug>\/parking-<poiSlug>",
        "route": "en\/park-in-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "en\/events-in-<poiId>\/<month:\\d+>",
        "route": "en\/events-in-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "en\/itv\/itv-booking-<poiSlug>",
        "route": "en\/itv\/itv-booking-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET en\/parking\/<idParking:\\d+>\/parking-pass\/<id:\\d+>",
        "route": "en\/parking\/:idParking\/parking-pass\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET en\/parking-pass\/<id:\\d+>\/new",
        "route": "en\/parking-pass\/:id\/new",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET en\/parking-pass\/<id:\\d+>\/show",
        "route": "en\/parking-pass\/:id\/show",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST en\/masmovil\/refuse-lot\/<token>",
        "route": "en\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "en\/masmovil\/refuse-lot\/<token>",
        "route": "en\/masmovil\/refuse-lot\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "en\/search-electric-chage-station",
        "route": "en\/search-electric-chage-station",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET en\/charging-point-booking\/<idBooking:\\d+>\/show",
        "route": "en\/charging-point-booking\/:idBooking\/show",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "en\/driver-plus\/fines-not-notified",
        "route": "en\/driver-plus\/fines-not-notified",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "en\/driver-plus\/contract",
        "route": "en\/driver-plus\/contract",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "en\/driver-plus\/subscriptions",
        "route": "en\/driver-plus\/subscriptions",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET en\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "en\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "en\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "en\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "en\/rental-car",
        "route": "en\/rental-car",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "en\/finish-rental-car",
        "route": "en\/finish-rental-car",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "en\/rental-car\/<id:\\d+>\/detail",
        "route": "en\/rental-car\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "en\/rental-car\/search",
        "route": "en\/rental-car\/search",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "en\/rental-car\/show\/<unbornId:\\d+>\/<uuid>",
        "route": "en\/rental-car\/show\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "en\/rental-car\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "en\/rental-car\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "site\/index": {
        "original_route": "en",
        "route": "en",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "en\/sitemap.xml",
        "route": "en\/sitemap.xml",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/en",
        "route": "esp\/en",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "en\/complete-profile",
        "route": "en\/complete-profile",
        "params": []
    }
};